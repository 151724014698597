import React from 'react'
import { useOutlet } from 'react-router-dom'
import ResponsiveAppBar from '../Components/Appbar'
import { Box, Typography } from '@mui/material'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';




function BaseLayout({ children }) {

  const outlet = useOutlet()

  return (
    <div style={{ display: "flex", backgroundColor: "#000", minWidth: "100vw", minHeight: "100vh", }} >
      {children}
      <Box sx={{ width: "100%", display: "flex", position: "fixed", zIndex: 2 }} >
        <ResponsiveAppBar />

        <Box sx={{ display: { sm: 'none', md: 'flex' } ,position: "fixed", bottom: 0, zIndex: 3, left: 0, width: "100%", justifyContent: "space-between", backgroundColor: "rgba(255,255,255,0.8)" }} >
          <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", padding: 10 }}>
            <LocalPhoneIcon sx={{ color: "#000", fontSize: 25 }} />
            <Typography sx={{ color: '#000', ml: 2,fontSize:20 }} variant="body1" gutterBottom> 0 530 123 64 83</Typography>
          </div>

          <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", padding: 10 }}>
            <AlternateEmailIcon sx={{ color: "#000", fontSize: 25 }} />
            <Typography sx={{ color: '#000', ml: 2 ,fontSize:20}} variant="body1" gutterBottom> info@azeta.ist</Typography>
          </div>

          <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", padding: 10 }}>
            <LocationOnIcon sx={{ color: "#000", fontSize: 25 }} />
            <Typography sx={{ color: '#000', ml: 2,fontSize:20 }} variant="body1" gutterBottom> MAHMUTBEY MAH. TASOCAĞI YOLU CAD. BALANCE GÜNEŞLİ NO: 19/1A BAĞCILAR / İSTANBUL</Typography>
          </div>
        </Box>

      </Box>
      {outlet}
    </div>
  )
}

export default BaseLayout