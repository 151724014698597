import { Box, Typography } from '@mui/material'
import React from 'react'

function LCard({ img, title, text }) {
    return (
        <Box sx={{ display: "flex", flexDirection: "column",mt:4 }} >

            <Typography sx={{ color: '#000',fontWeight: "bold", mb: 4 }} variant="h4" gutterBottom> {title}</Typography>

            <Box
                component="img"
                sx={{
                    borderRadius:"2%",height: {xs:250,sm:450,md:450},
                    width: "100%",
                }}
                src={img}
            />

            <Typography sx={{ color: '#000', mt: 4 ,fontSize:20}} variant="body1" gutterBottom>

                {text}
            </Typography>
        </Box>
    )
}

export default LCard