
import './App.css';
import { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import BaseLayout from './Layouts/BaseLayout';
import InfoPage from './Pages/InfoPage';
import WorkPage from './Pages/WorkPage';
import ContactPage from './Pages/ContactPage';
function Error404() {
  return (
    <div>
      <h2>Aradığınız Sayfa Bulunamadı</h2>
    </div>
  )
}

function App() {

  useEffect(() => {
    document.body.style.zoom = '80%';
  }, []);
  return ( 
    <>
      <Suspense fallback={<p>Yükleniyor....</p>} >
        <Routes>
          <Route element={<BaseLayout />} >
            <Route path="/" element={<HomePage />} />
            <Route path="/Hakkimizda" element={<InfoPage />} />
            <Route path="/Hizmetlerimiz" element={<WorkPage />} />
            <Route path="/İletişim" element={<ContactPage />} />

          </Route>

          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
