import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import MediaCard from '../Components/MCard'
import LCard from '../Components/LCard'

function WorkPage() {

  const [card, setCards] = useState([
  {
    img: "https://images.unsplash.com/photo-1513828583688-c52646db42da?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title:"Makine Hizmetleri",
    context:" Her türlü endüstriyel makine, ekipman ve aletlerin alımı, satımı, ithalatı ve ihracatı yapılacaktır. Makine Üretimi ve Montajı: Endüstriyel kullanıma yönelik makine ve ekipmanların tasarımı, üretimi, montajı ve kurulumu gerçekleştirilecektir. Makine Parça ve Aksesuar Satışı: Makineler için yedek parça, bileşen ve aksesuarların satışı sağlanacaktır. Makine Bakım ve Onarım Hizmetleri: Müşterilerin makine ve ekipmanlarının düzenli bakımı, onarımı ve revizyonu hizmetleri sunulacaktır. Makine Mühendisliği ve Danışmanlık: Endüstriyel süreçler için makine mühendisliği hizmetleri, proje yönetimi, danışmanlık ve eğitim faaliyetleri gerçekleştirilecektir. Özel Makine Tasarımı ve Üretimi: Müşteri ihtiyaçlarına yönelik özel makine ve ekipmanların tasarımı, prototip üretimi ve seri üretime geçiş süreçleri yönetilecektir. Bu doğrultuda, makine sektöründe faaliyet gösteren tedarikçilerle iş birlikleri kurulacak ve endüstriyel projelerin hayata geçirilmesi için çözüm odaklı hizmetler sunulacaktır."
  },
  {
    img: "https://images.unsplash.com/photo-1541888946425-d81bb19240f5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title:"İnşaat Hizmetleri",
    context:"her türlü inşaat faaliyetlerini yürütmek için inşaat yapmak, satmak, kendi hesabına arazi ve arsalar almak, bunlar üzerinde veya başkalarına ait arsa ve araziler üzerinde kat karşılığı binalar yapmak, satmak, inşaat taahhütleri, taşeronluklar yapmak ve müteahhitlik hizmetleri vermek amacıyla faaliyet gösterecektir."
  },
  {
    img: "https://images.unsplash.com/photo-1516937941344-00b4e0337589?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title:"Sanayi Hizmetleri",
    context:"Şirketimiz, sanayi ve makine sektörlerinde geniş bir yelpazede hizmet sunmaktadır. Makine tasarımından üretim hattı otomasyonuna, fabrika tasarımından kurulumuna kadar uzanan birçok alanda uzmanlığımız bulunmaktadır. Müşterilerimize yenilikçi çözümler sunarak üretim süreçlerini optimize etmeye ve verimliliği artırmaya odaklanıyoruz. Her aşamada kaliteli hizmet ve müşteri memnuniyeti ilkesiyle çalışarak sektördeki lider konumumuzu sürdürüyoruz"
  },
  {
    img: "https://images.unsplash.com/photo-1524292332709-b33366a7f165?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title:"Tekstil Hizmetleri",
    context:"Şirket, tekstil ve konfeksiyon sektöründe her türlü ham, yarı mamul ve mamul madde ve malzemelerin üretimi, alım ve satımı ile ithalat ve ihracatını yapacaktır. Ayrıca, tekstil ürünlerinin üretimi için gerekli olan makine ve teçhizatların alım, satım ve kiralamasını da gerçekleştirecektir"
  },
  {
    img: "https://images.unsplash.com/photo-1523848309072-c199db53f137?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title:"Madencilik Hizmetleri",
    context:"madencilik sektöründe geniş bir yelpazede hizmet sunmaktadır. Maden arama ve sondajından, maden işletme planlamasına ve maden çıkarma operasyonlarına kadar uzanan birçok alanda uzmanlığımız bulunmaktadır. Müşterilerimize güvenilir ve sürdürülebilir madencilik çözümleri sunarak, doğal kaynakların etkin ve verimli bir şekilde kullanılmasına katkıda bulunmayı amaçlıyoruz. Teknoloji ve uzmanlık birikimimizi kullanarak, çevresel ve sosyal sorumluluklarımızı da gözeterek madencilik sektöründe öncü bir role sahip olmayı hedefliyoruz"
  },
  {
    img: "https://images.unsplash.com/photo-1565793298595-6a879b1d9492?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title:"Lojistik Hizmetleri",
    context:"Şirket, her türlü kara, deniz ve hava taşımacılığı ile ilgili olarak nakliye hizmetleri, lojistik hizmetleri, depolama ve antrepo hizmetleri, gümrükleme hizmetleri sunacaktır. Ayrıca, taşımacılık ve lojistikle ilgili danışmanlık hizmetleri verecektir."
  },
  {
    img:"https://images.unsplash.com/photo-1652211955967-99c892925469?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title:"Gıda Hizmetleri",
    context:"Gıda hizmetleri alanında uzun yıllara dayanan deneyimimizle, müşterilerimize taze, kaliteli ve lezzetli ürünler sunmayı taahhüt ediyoruz. Ürünlerimiz, en iyi malzemeler kullanılarak hazırlanmakta ve hijyenik koşullarda sizlere ulaştırılmaktadır. Geniş ürün yelpazemizle, farklı damak tatlarına hitap eden çözümler sunuyoruz. İster bireysel tüketim için, ister toplu etkinliklerinizde kullanabileceğiniz ürünlerimizle her zaman yanınızdayız. Profesyonel ekibimiz, müşteri memnuniyetini en üst düzeyde tutmak için titizlikle çalışmaktadır. Gıda güvenliğine olan bağlılığımız ve sürdürülebilirlik ilkelerimizle, sektörde öncü bir konumda yer alıyoruz. Amacımız, her zaman sağlıklı ve lezzetli gıda seçenekleri sunarak, yaşam kalitenizi artırmaktır. Bize duyduğunuz güven için teşekkür ederiz"
  }

  ])

  return (
    <>

    
    <Box sx={{ width: "100%", bgcolor: "#eee",background:"linear-gradient(90deg, rgba(241,241,241,1) 0%, rgba(187,187,190,1) 35%, rgba(255,255,255,1) 100%)"}} >
     <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 20, p: 2 }} >
       <Container maxWidth="lg" sx={{ mb: 4,p:3 }} >
         <Typography sx={{ color: '#000', m: 2, fontWeight: "bold", mb: 4 }} variant="h4" gutterBottom> HiZMETLERİMİZ</Typography>
         <Typography sx={{ color: '#000', ml: 2 ,fontSize:20}} variant="body1" gutterBottom>
           Hoş geldiniz! Şirketimiz, geniş bir yelpazede faaliyet gösteren dinamik ve yenilikçi bir kuruluştur. Gıda, otomotiv, dayanıklı tüketim, tekstil, yedek parça ve elektrik malzemeleri, medikal ürünler ve organizasyon hizmetleri gibi çeşitli sektörlerde uzmanlaşmış bulunmaktayız.

           Şirketimiz, farklı sektörlerde geniş bir uzmanlık alanına sahip olup, müşteri memnuniyetini her zaman ön planda tutmaktadır. Kaliteli ürün ve hizmetlerimizle sektörde öncü olma hedefiyle çalışmalarımıza devam etmekteyiz.
         </Typography>

       </Container>


       <Box>
         <Grid sx={{ display: "flex", alignItems: "center", width: "100%", flexDirection: { xs: "column", sm: "column", md: "row" } }} container spacing={2} >
           <Container maxWidth="md" >
           {card && card.map((d) => {
             return (
               <LCard title={d.title} text={d.context} img={d.img} />
             )
           })}

           </Container>



         </Grid>

       </Box>




    




     </Box>
    <Box sx={{width:"100%",height:300}} >

    </Box>

    </Box>
    
    </>
  )
}

export default WorkPage