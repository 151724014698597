import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import { useNavigate } from 'react-router-dom';

const pages = [
  {r:"/",name:'Anasayfa' },
  {r:"/Hakkimizda",name:'Hakkımızda' },
  {r:"/Hizmetlerimiz",name:'Hizmetlerimiz'},
  {r:"/İletişim",name:'İletişim'}
];

function ResponsiveAppBar() {

  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (p) => {
  
    typeof p === "string" &&  navigate(p)
    
    setAnchorElNav(null);
  };



  return (
    <AppBar sx={{ backgroundColor: "rgba(255,255,255,0.7)" }} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
       

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },justifyContent:"space-between" }}>
          <Box
            component="img"
            sx={{
              display: { xs: 'flex', md: 'none' },
              m: 2,
              height: 100,
              width: 250,
            }}
            src="/logo2.png"
          />  


            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                mt:1,
                 "& .MuiMenu-paper": 
              { backgroundColor: "rgba(0,0,0,0.7)", },
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={()=>handleCloseNavMenu(page.r)}>
                  <Typography sx={{color:"#000",p:3}} variant='h4' textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
       

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } ,justifyContent:"space-between"}}>
          <Box
            component="img"
            sx={{
              display: { xs: 'none', md: 'flex' },
              m: 2,
              height: 100,
              width: 250,
            }}
            src="/logo2.png"
          />
          <Box sx={{display:"flex",width:"100%",justifyContent:"flex-end"}} >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={()=>handleCloseNavMenu(page.r)}
                sx={{ my: 2, color: '#000', display: 'block',fontSize:18 }}
              >
                {page.name}
              </Button>
            ))}

          </Box>
          </Box>

       
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
