import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import CssBaseline from '@mui/material/CssBaseline';

import history from './history';
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <BrowserRouter history={history} >
    <CssBaseline>
      <App />
    </CssBaseline>

  </BrowserRouter>

);
//
//
//

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals