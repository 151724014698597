import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import MediaCard from '../Components/MCard'

function InfoPage() {



  const [card, setCards] = useState([
    { img: "/m.jpg", title: "En İyi Hizmet", context: "Azeta Makine İnşaat Sanayi ve Ticaret Şirketi olarak, müşterilerimize en üstün hizmeti sunmak için çalışıyoruz. Her projede kaliteyi ve mükemmelliği ön planda tutarak, sektörde lider konumdayız." },
    { img: "/k.jpg", title: "En İyi Kalite", context: "Üstün mühendislik ve kaliteli malzemeler ile Azeta Makine İnşaat Sanayi ve Ticaret Şirketi, her zaman en yüksek standartları hedefler. Uzman ekibimiz ve modern teknoloji kullanımı ile projelerinizi zamanında ve eksiksiz olarak teslim ediyoruz." },
    { img: "c.jpg", title: "Uygun Maliyet", context: "Azeta Makine İnşaat Sanayi ve Ticaret Şirketi olarak, maliyet etkin çözümler sunarak müşterilerimizin bütçelerini koruyoruz. Verimliliği artıran ve maliyetleri düşüren yenilikçi yöntemlerimiz ile kaliteli hizmeti uygun fiyatlarla sunuyoruz." },

  ])



  return (


    <Box sx={{ width: "100%", bgcolor: "#eee",backgroundImage:"url('/n.jpg')",backgroundSize:"cover",backgroundPosition:"center", overflow: "auto" }} >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 20, p: 2 }} >
        <Container maxWidth="lg" sx={{ mb: 4,p:3 }} >
          <Typography sx={{ color: '#000', m: 2, fontWeight: "bold", mb: 4 }} variant="h4" gutterBottom> HAKKIMIZDA</Typography>
          <Typography sx={{ color: '#000', ml: 2 ,fontSize:20}} variant="body1" gutterBottom>
            Hoş geldiniz! Şirketimiz, geniş bir yelpazede faaliyet gösteren dinamik ve yenilikçi bir kuruluştur. Gıda, otomotiv, dayanıklı tüketim, tekstil, yedek parça ve elektrik malzemeleri, medikal ürünler ve organizasyon hizmetleri gibi çeşitli sektörlerde uzmanlaşmış bulunmaktayız.

            Şirketimiz, farklı sektörlerde geniş bir uzmanlık alanına sahip olup, müşteri memnuniyetini her zaman ön planda tutmaktadır. Kaliteli ürün ve hizmetlerimizle sektörde öncü olma hedefiyle çalışmalarımıza devam etmekteyiz.
          </Typography>

        </Container>


        <Box>
          <Grid sx={{ display: "flex", alignItems: "center", width: "100%", flexDirection: { xs: "column", sm: "column", md: "row" } }} container spacing={2} >

            {card && card.map((d) => {
              return (
                <Grid key={d.title} xs={12} md={4} item>
                  <MediaCard title={d.title} img={d.img} context={d.context} />
                </Grid>
              )
            })}



          </Grid>

        </Box>




        <Container maxWidth="lg" >

          <Typography sx={{ color: '#000', ml: 2 }} variant="body1" gutterBottom>


          </Typography>
        </Container>




      </Box>

    </Box>
  )
}

export default InfoPage