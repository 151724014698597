import React from 'react'
import { Box, Typography } from '@mui/material'


function HomePage() {
  return (
      <div>
    <div style={{display:"flex",justifyContent:"center",alignItems:"center", width:"100%",height:"100%",backgroundImage:"url('/pan.jpg')",backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover",position:"fixed",top:0,zIndex:1}} >
      
    <Typography sx={{position:"relative",color:"#fff",fontWeight:"bold"}} variant="h2" >
    Makine ve inşaat Sektörlerinde Lideriz 
     </Typography>

       </div>
 



      </div>
  )
}

export default HomePage