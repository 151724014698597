import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import { FaLocationDot, FaPhone, FaGlobe } from "react-icons/fa6";
const map = {
  center: [
    41.052488,
    28.810615
  ],
  zoom: 13
};


function ContactPage() {
  return (
    <Box sx={{ width: "100%", bgcolor: "#eee", background: "linear-gradient(90deg, rgba(241,241,241,1) 0%, rgba(187,187,190,1) 35%, rgba(255,255,255,1) 100%)" }} >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 20, p: 2 }} >


      <Container maxWidth="lg" >
        <Typography sx={{ color: '#000', m: 2, fontWeight: "bold", mb: 4 }} variant="h4" gutterBottom> İLETİŞİM</Typography>
        <Typography sx={{ color: '#000', ml: 2 ,fontSize:20}} variant="body1" gutterBottom>
        Size en iyi şekilde hizmet verebilmemiz için buradayız. Müşteri memnuniyeti önceliğimizdir ve her türlü sorunuz, geri bildiriminiz veya işbirliği teklifleriniz için bize ulaşabilirsiniz. Sizinle işbirliği yapmayı, ihtiyaçlarınıza uygun çözümler sunmayı ve her türlü sorunuza yanıt vermeyi sabırsızlıkla bekliyoruz.
       </Typography>
      </Container>
      <Box sx={{ height: "100%", width: '100%', display: "flex", justifyContent: { xs: "flex-start", sm: "flex-start", md: "space-around" }, color: "#000", flexDirection: { xs: "column", sm: "column", md: "column",lg:"row" }, alignItems: "center", padding: 10 }} >
          <div style={{minWidth:450 , textAlign:"center",margin:10, backgroundColor:"white",padding:15,borderRadius:10}} >
            <h1 ><FaLocationDot /> Adres:</h1>
            <h3>MAHMUTBEY MAH. TASOCAĞI YOLU CAD.<br /> BALANCE GÜNEŞLİ NO: 19/1A BAĞCILAR / İSTANBUL</h3>
          </div>

          <div style={{minWidth:450 , textAlign:"center",margin:10, backgroundColor:"white",padding:15,borderRadius:10}} >
            <h1><FaPhone />  Telefon:</h1>
            <h2>0 530 123 64 83</h2>
          </div>

          <div style={{minWidth:450 , textAlign:"center",margin:10, backgroundColor:"white",padding:15,borderRadius:10}} >
            <h1><FaGlobe /> Mail:</h1>
            <h2>info@azeta.ist</h2>
          </div>

        </Box>


<Box sx={{width:"100%",display:"flex",position:"relative",zIndex:1}} >
        <MapContainer style={{ height: 500, width: '100%', minHeight: 150 }} center={map.center} zoom={map.zoom} scrollWheelZoom={false}>
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={map.center}>
            <Popup>
              Konumu  <a target='_blank' href="https://www.google.com/maps/place/%C4%B0lkirem+Lavanta+Bah%C3%A7esi/@40.8613863,35.2449688,15z/data=!4m6!3m5!1s0x4086e352b6b556c7:0x28674df1c9d35e04!8m2!3d40.8613863!4d35.2449688!16s%2Fg%2F11y1d37ypp?entry=ttu">Haritalar</a> Uygulamasında Aç
            </Popup>
          </Marker>
        </MapContainer>

</Box>


    



      </Box>
      <Box sx={{ width: "100%", height: 100 }} >

      </Box>

    </Box>
  )
}

export default ContactPage